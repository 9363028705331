<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-multi"></i>{{ $t('Label Firmware') }}
    </h2>
     <div class="searchArea">
        <div class="row align-end">
      <v-select
        :label="$t('Label Type')"
        v-model="labelTypeListData"
        :placeholder="$t('Select label type')"
        class="form-select ml-3 col-3"
        item-text="name"
        item-value="item"
        :items="labelTypeList"
        outlined dense hide-details
        clearable
        return object
        >
      <template v-slot:item="{ item }">
                <span class="selection-item-list">{{ getText(item) }}</span>
      </template>
      <template v-slot:selection="{ item }">
                <span class="selection-v-select">{{ getText(item) }}</span>
      </template>
      </v-select>
       <v-select
        :label="$t('Label Class')"
        v-model="tagClassListData"
        :placeholder="$t('Select label class')"
        class="form-select ml-3 col-3"
        item-text="name"
        item-value="level"
        :items="tagClassList"
        outlined dense hide-details
        clearable
      ></v-select>
      <v-btn
        text
        class="btn type-search ml-2"
        style="height: 39px;"
        @click="searchOTAData"
      >{{ $t('Search') }}
      </v-btn>
    </div>
    </div>
    <v-data-table
      item-key="id"
      :headers="labelFirmwareListHeaders"
      :items="labelFirmwareList"
      :hide-default-footer="true"
      :items-per-page="10"
      fixed-header
      class="tbl-type01 mt-10 products"
      :options.sync="options"
    >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
    </v-data-table>
     <div class="table-options">
      <div>
    <label-firmware-uploader
      :btnDisabledFileUpload="btnDisabledFileUpload"
      v-on:initLabelFirmware="initLabelFirmware"
    />
    </div>
     <div class="pageInfo"> {{ pageInfoText }} </div>
      <div>
        <v-pagination
          v-model="page"
          @input="paging"
          :length="totalPages"
          :total-visible="7"
           class="pageAlign"
        ></v-pagination>
      </div>
      </div>
       <a ref="link" :style="{ display:'none' }" />
  </div>
</template>

<script>
import codes from '@/plugins/codes'
import EventBus from '@/plugins/eventBus'

// Components
import LabelFirmwareUploader from '@/views/Firmware/modal/LabelFirmwareUploader'
// Mixins
import SelectedStoreWatcher from '@/mixins/SelectedStoreWatcher'
import commons from '@/plugins/commons'

const requests = {
  otaType: {
    method: 'get',
    url: '/api/common/otamanager/api/otapackage/type'
  }
}
export default {
  mixins: [SelectedStoreWatcher],
  name: 'LabelFirmware',
  components: {
    LabelFirmwareUploader
  },

  data () {
    return {
      labelFirmwareListHeaders: [
        {
          text: '#',
          sortable: false,
          align: 'center',
          value: 'id',
          width: '9%'
        },
        {
          text: this.$t('SYSTEM'),
          sortable: true,
          align: 'start',
          value: 'system',
          width: '15%'
        },
        {
          text: this.$t('LABEL TYPE'),
          sortable: true,
          align: 'start',
          value: 'type',
          width: '15%'
        },
        {
          text: this.$t('CUSTOMER TYPE'),
          sortable: true,
          align: 'start',
          value: 'customer',
          width: '15%'
        },
        {
          text: this.$t('CODE'),
          sortable: true,
          align: 'start',
          value: 'typeCode',
          width: '10%'
        },
        {
          text: this.$t('LABEL CLASS'),
          sortable: true,
          align: 'start',
          value: 'tagClass',
          width: '12%'
        },
        {
          text: this.$t('VERSION'),
          sortable: true,
          align: 'start',
          value: 'fwVersion',
          width: '12%'
        },
        {
          text: this.$t('LAST UPDATED TIME'),
          sortable: true,
          align: 'start',
          value: 'lastModifiedDate'
        }
      ],
      labelFirmwareList: [],
      btnDisabledFileUpload: false,
      labelTypeList: [],
      tagClassList: [],
      labelTypeListData: '',
      tagClassListData: '',
      pageInfoText: '',
      page: 1,
      totalPages: 0,
      sort: 'id,asc',
      options: {}
    }
  },
  computed: {},
  watch: {
    options: {
      handler () {
        this.initLabelFirmware()
        this.page = 1
      },
      deep: true
    }
  },
  mounted () {
    // Select the company or load data.
    EventBus.$emit('enableSelectedStores', true)
    this.initLabelFirmware()
    // Check what buttons are inaccessable
    this.$store.dispatch('auth/getDisabledBtn', '10100').then(disabled => {
      this.btnDisabledFileUpload = disabled
    })
    this.togetOTAtype()
  },
  methods: {
    enableSearchBtn () {
      if (!this.tagClassListData && !this.labelTypeListData) {
        return false
      } else if (!this.tagClassListData) {
        return true
      } else if (!this.labelTypeListData) {
        return true
      }
    },
    paging () {
      this.initLabelFirmware()
      console.log(this.page)
    },
    togetOTAtype () {
      var config
      config = { params: {} }
      this.$utils
        .callAxios(
          requests.otaType.method,
          `${requests.otaType.url}`,
          config
        )
        .then(response => {
          console.log(response)
          this.labelTypeList = response.data.labelTypeList
          this.labelTypeList.sort(this.dynamicSort('type'))
          this.tagClassList = response.data.tagClassList.sort()
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    dynamicSort (property) {
      var sortOrder = 1
      if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
      }
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
      }
    },
    getText (item) {
      return item.type + ' ' + item.inch.replace(/p|P/g, '.') + '″'
    },
    searchOTAData () {
      this.page = 1
      this.initLabelFirmware()
    },
    // etc
    afterSelectedStoreUpdate () {
      this.initLabelFirmware()
    },
    async initLabelFirmware () {
      this.labelFirmwareList = this.convertLabelFirmwareList(
        await this.getLabelFirmwareList(this.page)
      )
      this.togetOTAtype()
    },
    convertLabelFirmwareList (labelFirmwareList) {
      return labelFirmwareList.map((labelFirmware, idx) => {
        labelFirmware.type = `${
          labelFirmware.type
        } ${labelFirmware.inch.replace(/p|P/g, '.')}″`
        labelFirmware.idx = idx + 1
        return labelFirmware
      })
    },

    // Added New
    handleGetfwResponse (res) {
      if (res.status === 204) {
        const headers = {}
        headers['x-totalelements'] = 0
        this.pageInfoText = commons.getPageInfoText(headers)
        this.totalPages = 0
      } else {
        this.pageInfoText = commons.getPageInfoText(res.headers)
        console.log(this.pageInfoText)
        if (commons.isValidStr(res.headers['x-totalpages'])) {
          this.totalPages = res.headers['x-totalpages'] * 1
        }
      }
    },
    handleGetfwError (error) {
      this.labelFirmwareList = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.totalPages = 0
      console.debug(`Could not find label firmware. error: ${error}`)
    },
    // API
    getLabelFirmwareList (page) {
      var config
      if (this.labelTypeListData || this.tagClassListData) {
        if (this.labelTypeListData) {
          config = {
            params: {
              type: this.labelTypeListData.type,
              inch: this.labelTypeListData.inch
            }
          }
          config.params.page = this.page - 1
        }
        if (this.tagClassListData) {
          config = {
            params: {
              tagClass: this.tagClassListData
            }
          }
          config.params.page = this.page - 1
        }
        if (this.tagClassListData && this.labelTypeListData) {
          config = {
            params: {
              type: this.labelTypeListData.type,
              inch: this.labelTypeListData.inch,
              tagClass: this.tagClassListData
            }
          }
          config.params.page = this.page - 1
        }
      } else {
        config = { params: {} }
        if (page !== null) config.params.page = page - 1
      }
      const { sortBy, sortDesc } = this.options
      let sort = null
      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        var sortKey
        sortKey = sortBy[0]
        if (sortKey === 'lastModifiedDate') {
          sortKey = 'updatedTime'
        }
        if (sortKey === 'type') {
          sortKey = 'type,inch'
        }
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) config.params.sort = sort
      return this.$utils
        .callAxios(
          codes.requests.getLabelFirmware.method,
          codes.requests.getLabelFirmware.url,
          config
        )
        .then(res => {
          this.handleGetfwResponse(res)
          this.$forceUpdate()
          return res.data.registeredPakcage ? res.data.registeredPakcage : []
        })
        .catch(error => {
          console.debug(`Could not find label firmware. error: ${error}`)
          this.handleGetfwError(error)
          this.$forceUpdate()
        })
    }
  }
}
</script>

<style>
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
.selection-v-select {
  position: absolute;
  top: 12px;
  overflow-x: hidden;
}
.selection-item-list{
      font-size: 0.8125rem !important;
    font-weight: 500;
    line-height: 1rem;
}
.pageAlign{
  float:right;

}
.tbl-type01 thead th {
    position: relative;
    border-bottom: 0 !important;
    background: #fafafa !important;
    color: #4d4f5c !important;
    font-weight: 700;
    font-size: var(--font14) !important;
    overflow: hidden;
}
.v-list-item--link:before {
    background-color:transparent !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
