<template>
  <v-dialog
    v-model="fileUploadVisible"
    scrollable
    persistent
    width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        :disabled="btnDisabledFileUpload"
        v-bind="attrs"
        v-on="on"
        class="btn mt-0"
        >{{ $t('File Upload') }}</v-btn
      >
    </template>

    <v-card class="popup add_store_popup"  >
      <v-card-title>
        <h3 class="page-title-bar">
        <i class="ico ico-reloadfirmware"></i>{{ $t('Firmware Upload') }}
      </h3>
    </v-card-title>
        <span class="page-title-bar" style="margin-bottom: 10px;font-size:16px;padding-left: 20px;">
        {{ $t('Upload File')}}*
      </span>

        <v-card-text  >
          <div style="width:400px">
  <v-radio-group v-model="radiobuttonValue" row>
        <v-radio label=".bin"  value="bin"></v-radio>
        <v-radio label=".zip" value="zip" class="ml-10"></v-radio>
      </v-radio-group>

      <v-row  >
        <v-col class="col-12" v-if="radiobuttonValue==='bin'">
          <v-file-input
            outlined
            dense
            hide-details
            prepend-icon
            solo
            flat
             accept=".bin"
            truncate-length="33"
            :placeholder="`${$t('Select file')}`"
            class="form-file"
            v-model="file"
            ref="inputFile"
            @change="previewFilesbin"
            @keydown="clearTabindex"

          >
            <template v-slot:append>
                  <span class="formatClass">.bin</span>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
          <v-col class="col-12" v-if="radiobuttonValue==='zip'">
          <v-file-input
            outlined
            dense
            hide-details
            prepend-icon
            solo
            flat
            truncate-length="33"
             accept=".zip"
            :placeholder="`${$t('Select file')}`"
            class="form-file"
            v-model="zipfile"
            ref="inputFile"
            @change="previewFileszip"
            @keydown="clearTabindex"
          >
            <template v-slot:append>
              <span class="formatClass">.zip</span>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <span style="color:red">
         {{ $t(uploadWarning) }}
      </span>
      <!-- <v-row>
        <v-col>
          <p class="label-txt">{{ $t('Selected Files') }}</p>
          <ul class="selectedFile-list mt-2">
            <li v-for="file in filesToUpload" :key="file.name">
              <p>{{ file.name }}</p>
              <v-btn @click="removeFile(file.name)" text icon
                ><img width="24" height="24" src="@/assets/img/ico-delete2.png"
              /></v-btn>
            </li>
          </ul>
        </v-col>
      </v-row> -->
      </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn v-if="radiobuttonValue==='bin'" text icon @click="uploadFiles()" class="btn">{{
          $t('Upload')
        }}</v-btn>
        <v-btn v-if="radiobuttonValue==='zip'" text icon @click="uploadLabelFirmwareMulti()" class="btn">{{
          $t('Upload')
        }}</v-btn>
        <v-btn text icon @click="cancelFileUpload()" class="btn">{{
          $t('Cancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />
  </v-dialog>
</template>

<script>
// Utils
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
// Mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
import FileUploader from '@/mixins/FileUploader'

export default {
  name: 'LabelFirmwareUploader',
  mixins: [FileUploader, MsgBoxParent],
  data () {
    return {
      testTrue: true,
      testFalse: false,
      zipfile: null,
      radiobuttonValue: 'bin',
      uploadWarning: ''
    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    async buildFileUploadBody (fileList) {
      const fileDataList = []
      // for (const f of fileList) {
      //   const fileData = {
      //     data: await commons.getBase64(f),
      //     fileName: file.name
      //   }
      //   fileDataList.push(fileData)
      // }
      const fileData = {
        data: await commons.getBase64(fileList),
        fileName: fileList.name
      }
      fileDataList.push(fileData)
      return fileDataList
    },
    async uploadFiles () {
      this.fileIsEmpty()
      if (this.fileIsEmpty(this.file)) {
        this.openMsgBox(this.$t('Please enter the Data.'))
        return
      }
      const body = { fileList: await this.buildFileUploadBody(this.file) }
      const params = {}
      const config = { params }
      this.$utils
        .callAxiosWithBody(
          codes.requests.uploadLabelFirmware.method,
          codes.requests.uploadLabelFirmware.url,
          body,
          config
        )
        .then(res => {
          this.openMsgBox(this.$t(res.data.responseMessage))
          this.$emit('initLabelFirmware')
          this.cancelFileUpload()
        })
        .catch(error => {
          if (error.response.data.responseCode > 300) {
            this.openMsgBox(this.$t(error.response.data.responseMessage))
          }
          console.debug(
            `Failed to upload label firmware package. error: ${error}`
          )
          this.cancelFileUpload()
        })
    },

    async uploadLabelFirmwareMulti () {
      this.fileIsEmpty()
      if (this.fileIsEmpty(this.zipfile)) {
        this.openMsgBox(this.$t('Please enter the Data.'))
        return
      }
      console.log(this.$refs.inputFile.value)
      const formData = new FormData()
      formData.append('file', this.$refs.inputFile.value)
      const params = {}
      const config = {
        params,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$utils
        .callAxiosWithBody(
          codes.requests.uploadLabelFirmwareMulti.method,
          codes.requests.uploadLabelFirmwareMulti.url,
          formData,
          config
        )
        .then(res => {
          this.openMsgBox(this.$t(res.data.responseMessage))
          this.$emit('initLabelFirmware')
          this.cancelFileUpload()
          this.zipfile = null
        })
        .catch(error => {
          if (error.response.data.responseCode > 300) {
            this.openMsgBox(this.$t(error.response.data.responseMessage))
          }
          console.debug(
            `Failed to upload label firmware package. error: ${error}`
          )
          this.cancelFileUpload()
          this.zipfile = null
        })
    },
    cancelFileUpload () {
      this.file = null
      this.zipfile = null
      this.filesToUpload = []
      this.finalimagearray = []
      this.toggleFileUpload()
      this.uploadWarning = ''
      this.$emit('callTorefreah')
    },
    previewFilesbin (event) {
      console.log(event)
      var re = /(\.bin)$/i
      if (!re.exec(event.name)) {
        this.$refs.inputFile.reset()
        this.uploadWarning = '* Upload Error : The File extension is not valid.'
      } else {
        this.uploadWarning = ''
      }
    },
    previewFileszip (event) {
      console.log(event)
      var re = /(\.zip)$/i
      if (!re.exec(event.name)) {
        this.$refs.inputFile.reset()
        this.uploadWarning = '* Upload Error : The File extension is not valid.'
      } else {
        this.uploadWarning = ''
      }
    },
    fileIsEmpty (file) {
      if (file === null || file === undefined || file === '') {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.formatClass{
  display: flex;
  align-items: center;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    overflow:hidden
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
