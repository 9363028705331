var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-multi" }),
        _vm._v(_vm._s(_vm.$t("Label Firmware")) + " ")
      ]),
      _c("div", { staticClass: "searchArea" }, [
        _c(
          "div",
          { staticClass: "row align-end" },
          [
            _c("v-select", {
              staticClass: "form-select ml-3 col-3",
              attrs: {
                label: _vm.$t("Label Type"),
                placeholder: _vm.$t("Select label type"),
                "item-text": "name",
                "item-value": "item",
                items: _vm.labelTypeList,
                outlined: "",
                dense: "",
                "hide-details": "",
                clearable: "",
                return: "",
                object: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "selection-item-list" }, [
                        _vm._v(_vm._s(_vm.getText(item)))
                      ])
                    ]
                  }
                },
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "selection-v-select" }, [
                        _vm._v(_vm._s(_vm.getText(item)))
                      ])
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.labelTypeListData,
                callback: function($$v) {
                  _vm.labelTypeListData = $$v
                },
                expression: "labelTypeListData"
              }
            }),
            _c("v-select", {
              staticClass: "form-select ml-3 col-3",
              attrs: {
                label: _vm.$t("Label Class"),
                placeholder: _vm.$t("Select label class"),
                "item-text": "name",
                "item-value": "level",
                items: _vm.tagClassList,
                outlined: "",
                dense: "",
                "hide-details": "",
                clearable: ""
              },
              model: {
                value: _vm.tagClassListData,
                callback: function($$v) {
                  _vm.tagClassListData = $$v
                },
                expression: "tagClassListData"
              }
            }),
            _c(
              "v-btn",
              {
                staticClass: "btn type-search ml-2",
                staticStyle: { height: "39px" },
                attrs: { text: "" },
                on: { click: _vm.searchOTAData }
              },
              [_vm._v(_vm._s(_vm.$t("Search")) + " ")]
            )
          ],
          1
        )
      ]),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 mt-10 products",
          attrs: {
            "item-key": "id",
            headers: _vm.labelFirmwareListHeaders,
            items: _vm.labelFirmwareList,
            "hide-default-footer": true,
            "items-per-page": 10,
            "fixed-header": "",
            options: _vm.options
          },
          on: {
            "update:options": function($event) {
              _vm.options = $event
            }
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")])
          ])
        ],
        2
      ),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c("label-firmware-uploader", {
              attrs: { btnDisabledFileUpload: _vm.btnDisabledFileUpload },
              on: { initLabelFirmware: _vm.initLabelFirmware }
            })
          ],
          1
        ),
        _c("div", { staticClass: "pageInfo" }, [
          _vm._v(" " + _vm._s(_vm.pageInfoText) + " ")
        ]),
        _c(
          "div",
          [
            _c("v-pagination", {
              staticClass: "pageAlign",
              attrs: { length: _vm.totalPages, "total-visible": 7 },
              on: { input: _vm.paging },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            })
          ],
          1
        )
      ]),
      _c("a", { ref: "link", style: { display: "none" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }