var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", width: "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn mt-0",
                      attrs: { text: "", disabled: _vm.btnDisabledFileUpload }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("File Upload")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.fileUploadVisible,
        callback: function($$v) {
          _vm.fileUploadVisible = $$v
        },
        expression: "fileUploadVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-reloadfirmware" }),
              _vm._v(_vm._s(_vm.$t("Firmware Upload")) + " ")
            ])
          ]),
          _c(
            "span",
            {
              staticClass: "page-title-bar",
              staticStyle: {
                "margin-bottom": "10px",
                "font-size": "16px",
                "padding-left": "20px"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Upload File")) + "* ")]
          ),
          _c("v-card-text", [
            _c(
              "div",
              { staticStyle: { width: "400px" } },
              [
                _c(
                  "v-radio-group",
                  {
                    attrs: { row: "" },
                    model: {
                      value: _vm.radiobuttonValue,
                      callback: function($$v) {
                        _vm.radiobuttonValue = $$v
                      },
                      expression: "radiobuttonValue"
                    }
                  },
                  [
                    _c("v-radio", { attrs: { label: ".bin", value: "bin" } }),
                    _c("v-radio", {
                      staticClass: "ml-10",
                      attrs: { label: ".zip", value: "zip" }
                    })
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _vm.radiobuttonValue === "bin"
                      ? _c(
                          "v-col",
                          { staticClass: "col-12" },
                          [
                            _c("v-file-input", {
                              ref: "inputFile",
                              staticClass: "form-file",
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                "prepend-icon": "",
                                solo: "",
                                flat: "",
                                accept: ".bin",
                                "truncate-length": "33",
                                placeholder: "" + _vm.$t("Select file")
                              },
                              on: {
                                change: _vm.previewFilesbin,
                                keydown: _vm.clearTabindex
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "formatClass" },
                                          [_vm._v(".bin")]
                                        ),
                                        _c("img", {
                                          attrs: {
                                            width: "24",
                                            height: "24",
                                            src: require("@/assets/img/ico-upload-file.gif")
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3440439784
                              ),
                              model: {
                                value: _vm.file,
                                callback: function($$v) {
                                  _vm.file = $$v
                                },
                                expression: "file"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.radiobuttonValue === "zip"
                      ? _c(
                          "v-col",
                          { staticClass: "col-12" },
                          [
                            _c("v-file-input", {
                              ref: "inputFile",
                              staticClass: "form-file",
                              attrs: {
                                outlined: "",
                                dense: "",
                                "hide-details": "",
                                "prepend-icon": "",
                                solo: "",
                                flat: "",
                                "truncate-length": "33",
                                accept: ".zip",
                                placeholder: "" + _vm.$t("Select file")
                              },
                              on: {
                                change: _vm.previewFileszip,
                                keydown: _vm.clearTabindex
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "formatClass" },
                                          [_vm._v(".zip")]
                                        ),
                                        _c("img", {
                                          attrs: {
                                            width: "24",
                                            height: "24",
                                            src: require("@/assets/img/ico-upload-file.gif")
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                923340142
                              ),
                              model: {
                                value: _vm.zipfile,
                                callback: function($$v) {
                                  _vm.zipfile = $$v
                                },
                                expression: "zipfile"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(" " + _vm._s(_vm.$t(_vm.uploadWarning)) + " ")
                ])
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _vm.radiobuttonValue === "bin"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.uploadFiles()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Upload")))]
                  )
                : _vm._e(),
              _vm.radiobuttonValue === "zip"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.uploadLabelFirmwareMulti()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Upload")))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancelFileUpload()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("msg-box", {
        attrs: { msgBox: _vm.msgBox },
        on: { closeMsgBox: _vm.closeMsgBox }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }